import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material"
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker, TimeView } from "@mui/x-date-pickers"
import { FormProps, FormPropsField } from '../../types/props'
import { getRgbaParts, submitForm } from '../../services/HelperService'
import { useRecipient } from '../../contexts/RecipientContext'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Survey } from '../../types/form'
import InputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask'

import './FormRenderer.scss'
import { DateTime } from 'luxon'
import { us_states } from '../../data/us.states'

export const FormRenderer = ({form, step = null, onStepUpdated}: {form: FormProps, step?: number|null, onStepUpdated: (step: number) => void}) => {
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [model, setModel] = useState<{[key: string]: any}>({})
  const {recipient, token, setRecipient} = useRecipient()
  const [mode, setMode] = useState<string>('form')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [survey, setSurvey] = useState<Survey>(null)
  const [surveyAnswer, setSurveyAnswer] = useState<any>(null)
  const [surveyError, setSurveyError] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const fieldStyle = {
		display: 'block',
		width: '100%', 
		marginBottom: '10px',
		borderRadius: form.fieldStyle.borderRadius,
		'.MuiInputBase-root': {
			width: '100%',
			borderRadius: form.fieldStyle.borderRadius,
			backgroundColor: form.fieldStyle.backgroundColor,
		},
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: `${form.fieldStyle.borderColor}!important`
		},
		'.MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
			color: `${form.fieldStyle.borderColor}`
		},
		'.MuiFilledInput-underline:after': {
			borderBottomColor: `${form.fieldStyle.borderColor}!important`
		},
		'.MuiFilledInput-underline:before': {
			borderBottomColor: `${form.fieldStyle.borderColor}!important`
		},
		'.Mui-error': {
			'&.MuiFormHelperText-root': {
				marginTop: 0,
				position: 'absolute',
				bottom: '-2px',
				transform: 'translateY(100%)',
				fontSize: '10px'
			},
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `#d32f2f!important`
			},
			'.MuiFilledInput-underline:after': {
				borderBottomColor: `#d32f2f!important`
			},
			'.MuiFilledInput-underline:before': {
				borderBottomColor: `#d32f2f!important`
			},
		}
	}
  let button_rgba = form.button.style.backgroundColor ? getRgbaParts(form.button.style.backgroundColor) : null
  let button_hover

  if (button_rgba) {
    button_hover = `rgba(${button_rgba[1]},${button_rgba[2]},${button_rgba[3]},${parseInt(button_rgba[4]) - 0.1})`
  }

  useEffect(() => {
    if (recipient) {
      setModel(recipient)

      if (recipient.initialOptions?.content_type === 'POLL') {
        setSurvey(recipient.initialOptions)
        setMode('survey')
      }
      
      setLoading(false)
    }
  }, [recipient])


  const checkValue = (type: string, value: any, required: boolean, label: string): any => {  
    if (!value) {
      if (required) {
        return `${label} is required.`
      }
    } else {
      let valid = true
      switch (type) {
        case 'email':
          valid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
          
          if (!valid) {
            return 'Please enter a valid email address.'
          }
          break
        case 'phone':
          valid = !value.includes('_')

          if (!valid) {
            return 'Please enter a valid phone number.'
          }
          break
      }
    }
    
    return null
	}

  const checkFormValue = (field: FormPropsField): any => {
    let new_errors: {[key: string]: string} = {...errors}
    let error = checkValue(field.type, model[field.key], field.required, field.label)
    
    if (error) {
      new_errors[field.key] = error
    } else if (errors[field.key]) {
      delete new_errors[field.key]
    }

    setErrors(new_errors)
    
    return new_errors
	}

  const checkSurveyValue = (type: string, event: string): string | null => {
    let error = checkValue(type, surveyAnswer, true, 'Answer')

    if (surveyError || event !== 'change') {
      setSurveyError(error)
    }

    return error
  }

  const onModelChange = (field: FormPropsField, event: any) => {
    let new_model = {...model}

    if (field.type === 'opt-in') {
      new_model[field.key] = event.target.checked
    } else {
      new_model[field.key] = event.target.value
    }

    setModel(new_model)
  }

  const onSubmitForm = () => {
    setSubmitting(true)

    if (mode === 'survey') {
      let error = null

      if (survey.question) {
        error = checkSurveyValue(survey.question.type, 'submit')
      }

      if (error) {
        setSubmitting(false)
      } else {
        let request: any
  
        if (survey.content_type === 'APPT') {
          request = {
            token, 
            time: surveyAnswer
          }
        } else if (survey.question?.type === 'Multiple Choice') {
          request = {
            token, 
            answer: surveyAnswer,
            question: survey.question.id
          }
        } else {
          request = {
            formAnswer: surveyAnswer,
            token
          }
        }
  
        submitForm(request).then((response) => {
          if (['POLL', 'APPT'].includes(response.data.content_type)) {
            setSurvey(response.data)
          } else if (response.data.content_type === 'CONFIRM') {
            setRecipient({...recipient,
              redemption:response.data
            })

            onStepUpdated(-1)
          }

          setSurveyAnswer(null)
          setSubmitting(false)
        })
      }
    } else {
      let submit_errors: any = {}
      let model_to_submit: any = {}
      let can_submit: boolean = true
      let last_step = step + 1 === form.fields.length
  
      form.fields[step].forEach(group => {
        group.forEach(field => {
          model_to_submit[field.key] = model[field.key]
          submit_errors = {...submit_errors, ...checkFormValue(field)}
          if (submit_errors[field.key]) can_submit = false
        })
      })
  
      setErrors(submit_errors)
  
      if (can_submit) {
        submitForm({
          ...model_to_submit,
          token,
          form_completed: last_step
        }).then((response: any) => {
          if (!last_step) {
            onStepUpdated(step + 1)
          } else {
            // Check reponse for whether or not the survey is enabled.
            if (['POLL', 'APPT'].includes(response?.data?.content_type)) {
              setMode('survey')
              setSurvey(response.data)
            } else {
              // -1 is the thank you page
              onStepUpdated(-1)
            }
          }
  
          setSubmitting(false)
        })
      } else {
        setSubmitting(false)
      }
    }
  }

  const getPhoneField = (label: string, required: boolean, extraSettings: any, error: string | null) => {
    const modifyAutofill = (request: BeforeMaskedStateChangeStates) => {
      return {...request.nextState}
    }

    return <InputMask mask="+1 (999) 999-9999" 
      beforeMaskedStateChange={modifyAutofill}
      {...extraSettings}>
      <TextField size={form.fieldStyle.size}      
        placeholder={label}
        required={required}
        type="tel"
        error={error ? true : false}
        helperText={error}
        sx={{
          ...fieldStyle,
          'input': {
            borderRadius: 'inherit'
          }
        }}/>
    </InputMask>
  }

  const getTextField = (label: string, required: boolean, type: string, extraSettings: any, floatingLabel: boolean = true) => {
    switch(type) {
      case 'number': 
        extraSettings = {
          ...extraSettings,
          type: 'number'
        }
        break
    }

    return <TextField 
      sx={{
        ...fieldStyle,
        'input': {
          borderRadius: 'inherit'
        }
      }}
      FormHelperTextProps={{
        margin: 'dense',
        required: true
      }}
      size={form.fieldStyle.size}
      label={form.fieldStyle.floatingLabel && floatingLabel ? label: ''}
      placeholder={label}
      required={required}
      variant={form.fieldStyle?.fieldType} 
      { ...extraSettings }/>
  }

  const getDateField = (label: string, extraSettings: any) => {
    return <DatePicker sx={{
        ...fieldStyle
      }}
      slotProps={{ textField: {
        size: form.fieldStyle.size
      }}}
      label={label}
      {...extraSettings}/>
  }

  const getSelectField = (label: string, key: string, required: boolean, extraSettings: any, options: any[], error: string) => {
    return <FormControl key={key}
      sx={{
        ...fieldStyle
      }} 
      required={required}
      size={form.fieldStyle.size}
      fullWidth>
      <InputLabel id={key}>
        { label }
      </InputLabel>
      <Select labelId={key}
        label={ label }
        variant={form.fieldStyle.fieldType}
        sx={{
          backgroundColor: `${form.fieldStyle.backgroundColor}!important`
        }}
        { ...extraSettings }>
        { options }
        { error && <FormHelperText>{error}</FormHelperText>}
      </Select>
    </FormControl>
  }

  const getRadioField = (label: string, options: any[], extraSettings: any) => {
    return <FormControl>
      { label && <FormLabel id={label}>
        { label }
      </FormLabel> }
      <RadioGroup {...extraSettings}
        sx={{
          '.MuiButtonBase-root': {
            color: form.fieldStyle.borderColor,
            '&.Mui-checked': {
              color: form.fieldStyle.borderColor
            }
          }
        }}
        aria-labelledby={label}>
        { options }
      </RadioGroup>
    </FormControl>
  }

  const getAppointmentField = () => {
    const shouldDisableDate = (day: DateTime) => {
      let day_of_week = day.toFormat('cccc').toLowerCase()
      const hours: any = survey.event_hours
      return hours[`${day_of_week}_from`] === hours[`${day_of_week}_to`]
    }

    const shouldDisableTime = (value: DateTime, view: TimeView) => {
      if (view === 'hours') {
        let day_of_week = value.toFormat('cccc').toLowerCase()
        const hour = parseInt(value.toFormat('HH'))
        const hours: any = survey.event_hours

        return hour < parseInt(hours[`${day_of_week}_from`]) || hour > parseInt(hours[`${day_of_week}_to`])
      } 
      
      return false
    }

    return <DateTimePicker sx={{...fieldStyle}}
      disablePast={true}
      maxDate={DateTime.fromFormat(survey.end_date, 'yyyy-MM-dd')}
      onChange={(newValue: DateTime) => {setSurveyAnswer(newValue.toISO())}}
      shouldDisableDate={shouldDisableDate}
      shouldDisableTime={shouldDisableTime}
      disableIgnoringDatePartForTimeValidation={true}
    />
  }

  const getTimeField = (label: string, floatingLabel: boolean) => {
    return <TimePicker
      label={floatingLabel ? label : ''}
      onChange={(newValue: DateTime) => {setSurveyAnswer(newValue.toFormat('HH:mm'))}}
    />
  }

  const getField = (field: FormPropsField, index: number) => {
    let extraSettings: any = {
      key: index
    }
  
    switch(field.type) {
      case 'phone': 
        extraSettings = {
          ...extraSettings,
          value: model[field.key] || '',
          onChange: (event: any) => onModelChange(field, event),
          onBlur: (event: any) => checkFormValue(field)
        }
        return getPhoneField(field.label, field.required, extraSettings, null)
      case 'text': 
      case 'number':
      case 'email': 
        extraSettings = {
          ...extraSettings,
          error: errors[field.key] !== undefined,
          value: model[field.key] || '',
          helperText: errors[field.key],
          onChange: (event: any) => onModelChange(field, event),
          onBlur: (event: any) => checkFormValue(field)
        }			

        return getTextField(field.label, field.required, field.type, extraSettings)
      case 'select': 
        const options = field.options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
        let selectSettings = {
          value: model[field.key] || '',
          onClose: (event: any) => checkFormValue(field),
          onChange: (event: any) => onModelChange(field, event)
        }

        return getSelectField(field.label, index.toString(), field.required, selectSettings, options, errors[field.key])
      case 'date':
        return getDateField(field.label, extraSettings)
      case 'opt-in': 
        if (model[field.key] === undefined && field.pre_check) {
          let new_model = {...model}
          new_model[field.key] = true
      
          setModel(new_model)
        }

        const getTermsAndPrivacy = (field: FormPropsField) => {
          if (field.terms) {
            if (field.privacy) {
              return <> View <a href={field.terms} target="_blank">Terms of Conditions</a> and <a href={field.privacy} target="_blank">Privacy Policy</a>.</>
            } else {
              return <> View <a href={field.terms} target="_blank">Terms of Conditions</a>.</>
            }
          } else {
            if (field.privacy) {
              return <> View <a href={field.privacy} target="_blank">Privacy Policy</a>.</>
            } else {
              return <></>
            }
          }
        }

        return  <FormControlLabel key={index}
          className="dm-edit-form-optin"
          control={
            <Checkbox checked={model[field.key] || false} 
              onChange={(event: any) => onModelChange(field, event)} 
              name={field.label} />
          }
          label={
            <div style={{fontSize: field.fontSize, lineHeight: 1.2}}>
              {field.message} 
              {getTermsAndPrivacy(field)}
            </div>
          }
        />
    }
  }

  const getSurveyField = () => {
    if (survey.content_type === 'APPT') {
      return getAppointmentField()
    } 

    let extraSettings: any = {
      value: surveyAnswer || '',
      onChange: (event: any) => { 
        setSurveyAnswer(event.target.value)
        checkSurveyValue(type, 'change')
      }
    }

    let type = 'text'
    const label = survey.question.text
    // Logic that renders fields (see getField as an example)
    switch (survey.question.type) {
      case 'Multiple Choice':
        const options = survey.answers.map(answer => <MenuItem key={answer.id} value={answer.id}>{answer.text}</MenuItem>)
        return getSelectField(label, survey.question.id.toString(), true, extraSettings, options, null)
      case 'Yes/No':
        const radioOptions = [{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}].map(option => <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />)
        return getRadioField('', radioOptions, extraSettings)
      case 'Date':
        const dateExtraSettings = {
          onChange: (event: DateTime) => {
            setSurveyAnswer(event.toFormat('yyyy-MM-dd')) 
          }
        }
        return getDateField(label, dateExtraSettings) 
      case 'Time':
        return getTimeField(label, false)
      case 'Appointment':
      case 'Month':
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const monthOptions = months.map(month => <MenuItem key={month} value={month}>{month}</MenuItem>)
        return getSelectField(label, survey.question.id.toString(), true, extraSettings, monthOptions, null)
      case 'Day Of Week':
        const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        const weekdayOptions = weekdays.map(weekday => <MenuItem key={weekday} value={weekday}>{weekday}</MenuItem>)
        return getSelectField(label, survey.question.id.toString(), true, extraSettings, weekdayOptions, null)
        break
      case 'US State':
        const stateOption = us_states.map(state => <MenuItem key={state.value} value={state.label}>{state.label}</MenuItem>)
        return getSelectField(label, survey.question.id.toString(), true, extraSettings, stateOption, null)
      case 'Phone Number':
        type = 'phone'
        extraSettings = {
          ...extraSettings,
          onBlur: (event: any) => checkSurveyValue(type, 'blur')
        }
        return getPhoneField(label, true, extraSettings, surveyError)
      case 'City':
      case 'Email':
      case 'Number':
      case 'AlphaNumeric':
        switch (survey.question.type) {
          case 'Number':
            type = 'number'
            break
          case 'Email':
            type = 'email'
            break
        }

        extraSettings = {
          ...extraSettings,
          onBlur: (event: any) => checkSurveyValue(type, 'blur'),
          error: surveyError ? true : false,
          helperText: surveyError
        }

        return getTextField(label, true, type, extraSettings, false)
    }
  }

  return loading ? <></> :
  (step === -1 || survey?.content_type === 'CONFIRM') ? 
    <div className="dm-edit-form__confirmation">
      <h3 style={form.confirmation.title.style}>
        { form.confirmation.title.text }
      </h3>
      { form.confirmation.description.text &&
        <p style={form.confirmation.description.style}>
          { form.confirmation.description.text }
        </p>
      }
      { form.confirmation.external_link && 
        <Button sx={{display: 'block', 
          textTransform: 'none', 
          textAlign: 'center',
          ...form.confirmation.link.style}}
          href={form.confirmation.link.url}>
          { form.confirmation.link.label }
        </Button>
      }
    </div> : 
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <form style={{
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        gap: form.marginY
      }}>
        { mode === 'form' ? <>
            { form.label.text && 
              <h2 style={{
                margin: 0,
                textAlign: form.top_alignment,
                ...form.label.style
              }}>
                { form.label.text }
              </h2> 
            }
            { form.description.text && 
              <p style={{
                margin: 0,
                lineHeight: 1.5,
                textAlign: form.top_alignment,
                ...form.description.style
              }}>
                { form.description.text }
              </p> 
            }
            {	form.fields?.[step] &&
              form.fields[step].map((group, index) => 
                <div key={index}
                  className="dm-edit-form__group"
                  style={{gap: form.marginX}}>
                  { group.map((field, field_index) => getField(field, field_index)) }
                </div>)
            }
          </> :
          <>
            {/* Here goes survey logic */}
            <h2 style={{
                margin: 0,
                textAlign: form.top_alignment,
                ...form.label.style
              }}>
              { survey.text_header }
            </h2> 
            <h4 style={{
              margin: 0,
              ...form.description.style            
            }}>
              { survey.question?.text }
            </h4>
            { getSurveyField() }
          </>
        }
        <Button sx={{
            display: 'block', 
            textTransform: 'none', 
            ...form.button.style,
            '&:hover': {
              backgroundColor: button_rgba ? button_hover : 'inherit'
            },
            '&.Mui-disabled': {
              ...form.button.style
            }
          }}
          disabled={submitting}
          onClick={onSubmitForm}>
          {
            submitting ? <CircularProgress style={{verticalAlign: 'middle'}} 
              size="16px" 
              color="inherit" /> : mode === 'survey' ? survey.submit_text : form.button.label
          }
        </Button>
      </form>
    </LocalizationProvider>
}

const InputMaskComponent = React.forwardRef((props, inputRef) => {
  return <InputMask mask="1 (999) 999-9999" {...props} />
})
